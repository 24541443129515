:root {
  /* --secondary-solid-color: rgb(6 141 114); */
  --secondary-solid-color: #45d09a;
}
.menu-labels {
  color: #0ae7fe;
}
.highliter {
  color: #0ae7fe;
}
.labels {
  color: #fff;
}
.link {
  color: #f1f1f1;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  position: relative;
}
.activeLink {
  /* color: var(--secondary-solid-color); */
  color: #45d09a;
  /* font-weight: 700; */
  /* font-size: 16px; */
}
.activeLink::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 110px;
  height: 2px;
  /* background-color: rgb(6 141 114); */
  background-color: #45d09a;
}
.link:not(.activeLink)::after {
  display: none;
}

/* Custom CSS For Solana Connect Button */
.wallet-adapter-button {
  background: linear-gradient(rgb(19 107 127), rgb(6 141 114)) !important;
  color: white !important;
  border-radius: 1.5px;
  padding: 5px 15px;
}

/* Media Queries */
@media screen and (min-width: 320px) {
  .navbar-container {
    width: 100%;
    padding: 0 2rem;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #232323;
  }
  .logo {
    width: 10rem;
  }
  .navbar-sidebar {
    color: white;
    width: 80%;
    height: 100vh;
    background-color: rgba(12, 12, 15);
    position: absolute;
    top: 0;
    right: 0;
  }
  .navbar-sidebar > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4rem 0;
    gap: 2rem;
  }
  .navbar-sidebar > ul > li > a {
    /* color: white; */
    /* padding: 0 1.5rem; */
  }
}
@media screen and (min-width: 768px) {
  .logo {
    width: 13rem;
  }
}
@media screen and (min-width: 992px) {
  .navigation-container {
    width: 100%;
    margin: auto;
    padding: 0 2.5rem;
  }
  .logo {
    width: 15rem;
  }
  .navbar-sidebar {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .navigation-container {
    max-width: 100rem;
  }
}
