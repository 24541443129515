.titles {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: left;
  /* color: #9b9797; */
  color: #45d09a;
  margin-top: 20px;
}
.default_exch_container {
  margin: 32px;
  background-color: #17171b;
  top: 282px;
  left: 68px;
  padding: 32px;
  gap: 64px;
  padding-bottom: 100px;
}
