* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
:root {
  --home-btn-color: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  );
  --home-text-color: #00bd9f;
  --home-text-gray: #9b9797;
  --home-footer-bg: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  );
}
