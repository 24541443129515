a {
  text-decoration: none;
  color: #fff;
}
li {
  list-style: none;
}
body {
  background: #0d0d0e;
}
/* Utility Classes */
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Navbar Starts Here */
.launch-btn,
.token-btn,
.tutorial-check-btn {
  background: var(--home-btn-color);
  padding: 0.6rem 1.5rem;
  border-radius: 8px;
}
.rm-border {
  border: none;
  color: white;
}

.token-btn {
  padding: 0.6rem 3rem;
}

.token-btn-fullwidth {
  display: inline-block;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.tutorial-docs-btn {
  border: 1px solid var(--home-text-color);
  border-radius: 8px;
  color: var(--home-text-color);
  padding: 0.5rem 2rem;
}

/* Marquee */
.marquee {
  margin: 0.7rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.9rem;
}
.marquee-img {
  width: 100%;
  max-width: 50px;
}

/* Navbar Ends Here */
/* Footer */
.footer {
  background: var(--home-footer-bg);
}
.github-icon {
  background: #0c3f3d;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 1rem;
}

/* Media Queries */
@media screen and (min-width: 320px) {
  .navigation-container {
    width: 100%;
    padding: 0 0.6rem;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: rgba(12, 12, 15, 0.15);
    backdrop-filter: blur(16px);
  }
  .logo {
    width: 10rem;
  }
  .mobile-sidebar {
    color: white;
    width: 60%;
    height: 100vh;
    background-color: rgba(12, 12, 15);
    position: absolute;
    top: 0;
    right: 0;
  }
  .mobile-sidebar > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4rem 0;
    gap: 3rem;
  }
  .mobile-sidebar > ul > li > a {
    color: white;
    padding: 0 1.5rem;
  }
  /* Hero Section */
  .home-wrapper {
    width: 100%;
    margin: 1rem 0;
  }
  .heroImg {
    width: 100%;
  }
  .hero-heading {
    font-weight: 700;
    line-height: normal;
  }
  /* Features Section */
  .feature-container {
    width: 100%;
    padding: 3rem 1rem;
    margin: 5rem 0;
  }
  .feature-heading,
  .tutorial-heading {
    font-weight: 700;
    line-height: normal;
    color: white;
  }
  /* Tutorial */
  .tutorial-user-card {
    /* width: 100%; */
    border-radius: 16px;
    padding: 1rem;
    background: linear-gradient(
      66deg,
      #116b5b 14.71%,
      rgba(10, 10, 13, 0) 88.13%
    );
    margin-bottom: 2rem;
    backdrop-filter: blur(2px);
  }

  .tutorial-user-card-container {
    width: 100%;
    margin-top: -2rem;
  }
  .tutorial-user-card > p {
    color: #fff;
    font-weight: 700;
    line-height: normal;
    font-size: 1.5rem;
  }
  .tutorial-docs-btn,
  .tutorial-check-btn {
    width: 100%;
    text-align: center;
  }
  .circle {
    width: 2rem;
    height: 2rem;
    border: 1px solid #9b9797;
    color: #9b9797;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  /* Statistics */
  .statistics-container {
    width: 100%;
    margin: 2rem 0;
    padding: 7rem 0 0 0;
  }
  .statics-img {
    width: 100%;
  }
  .statistics-heading {
    font-weight: 700;
    line-height: normal;
    color: #fff;
    font-size: 2rem;
    margin: 2rem 0;
  }
  .statistics-title {
    color: #00bd9f;
    font-weight: 700;
    font-size: 2rem;
  }
  /* Products Section*/
  .product-container {
    width: 100%;
    padding: 9rem 1rem;
  }
  .product-heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
  }
  .product-card-container {
    width: 100%;
    max-width: 20rem;
    padding: 1.5rem 1rem;
    background: #181818;
    border: 1px solid #212121;
    border-radius: 8px;
    box-shadow: 0px 5px 22.8px 0px rgba(46, 46, 46, 0.33);
  }
  .product-card-logo {
    margin: -1rem 0 0 2rem;
  }
  .product-card-text {
    text-align: center;
    color: #9f9f9f;
    font-weight: 500;
    margin: 1rem 0;
  }
  .product-button {
    padding: 0.5rem 1.25rem;
    background: linear-gradient(149deg, #02db5b -29.94%, #4977c1 135.03%);
    border-radius: 0.5rem;
    font-size: 1.2rem;
    margin: 0.7rem 0 0 0;
  }

  /* Pricing section */
  .pricing-container {
    padding: 7rem 0 0 0;
  }
  .Pricing-heading {
    color: #fff;
    margin: 1.7rem 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;
  }
  .pricing-card {
    border: 1px solid #292929;
    border-radius: 16px;
    height: 100%;
    min-height: 20rem;
  }
  .pricing-card-title {
    color: #f1f1f1;
    font-size: 1.25rem;
  }
  .pricing-card-price {
    color: #f1f1f1;
    font-size: 2.5rem;
    font-weight: 700;
  }
  .payByLogo {
    width: 100%;
  }
  /* Footer */
  .footer-container {
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .logo {
    width: 13rem;
  }
  .launch-btn {
    margin-left: 1rem;
  }
  /* .mobile-sidebar {
    display: none;
  } */
  /* Hero Section */
  .heroImg {
    width: 21rem;
  }
  .hero-heading {
    font-size: 2rem;
  }
  /* Feature Section */
  .feature-heading,
  .tutorial-heading {
    font-size: 3rem;
  }
  /* Tutorial Section */
  .tutorial-container {
    width: 100%;
    margin: 10rem 0;
  }
  .tutorial-user-card-container {
    width: 26rem;
    margin-top: -3.5rem;
    margin-left: 1.5rem;
  }
  .tutorial-docs-btn,
  .tutorial-check-btn {
    width: 50%;
  }
  /* Statistics */
  .statistics-heading {
    font-size: 2rem;
  }
  /* Pricing section */
  .Pricing-heading {
    font-size: 2rem;
  }
  .payByLogo {
    width: 20rem;
  }
  /* Product section */
  .product-card-logo {
    margin: -1.5rem 0 0 2.2rem;
  }
  .product-heading {
    font-size: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .navigation-container {
    width: 100%;
    margin: auto;
    padding: 0 2.5rem;
  }
  .logo {
    width: 15rem;
  }
  .mobile-sidebar {
    display: none;
  }
  /* Hero Section */
  .home-wrapper {
    width: 90%;
    margin: auto;
    max-width: 100rem;
  }
  .heroImg {
    width: 38rem;
  }
  .hero-heading {
    font-size: 4rem;
  }
  /* Feature Section */
  .feature-container {
    width: 85%;
    margin: 2rem auto;
  }
  /* Tutorial Section */
  .tutorial-container {
    width: 86%;
    margin: 8rem auto;
  }
  .tutorial-user-card > p {
    font-size: 2rem;
  }
  .tutorial-user-card-container {
    width: 30rem;
  }
  /* Statistics */
  .statistics-container {
    width: 80%;
    margin: 2rem auto;
  }
  .statistics-heading {
    font-size: 3rem;
  }
  .statistics-title {
    font-size: 3rem;
  }
  /* Pricing section */
  .Pricing-heading {
    font-size: 3rem;
  }
  .payByLogo {
    width: 14rem;
  }
  /* Products */
  .product-container {
    width: 100%;
    margin: 2rem auto;
    padding: 8rem 0;
  }
  .product-heading {
    font-size: 3rem;
  }
}
@media screen and (min-width: 1440px) {
  .navigation-container {
    max-width: 100rem;
  }
  /* Statistics */
  .statistics-left-container {
    width: 48%;
  }
}

/* Language */
#google_translate_element {
  width: 300px;
  float: right;
  text-align: right;
  display: block;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}
.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}
.activity-root {
  display: hide !important;
}
.status-message {
  display: hide !important;
}
.started-activity-container {
  display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  display: none;
  background-color: unset !important;
  box-shadow: none !important;
}
/* Video Modal CSS */
.custom-modal-header {
  background-color: #353535a1;
  border-bottom: none;
}

.custom-modal-body {
  background-color: #353535a1;
  padding: 15px 15px;
}

.custom-modal-header .btn-close {
  outline: none;
  box-shadow: none;
}
