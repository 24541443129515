.refresh-modal-header {
  color: #f1f1ff;
  margin: 0px 0px 10px 0px;
  font-size: 24px;
  font-weight: 500;
}
.refresh-modal-para {
  color: rgba(255, 255, 255, 1);
}
/* Outer Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.reset-btn {
  background: #45d09a;
  padding: 0.5rem 1rem;
  font-weight: 700;
  color: #fff;
}
.cancel-btn {
  background: rgba(30, 30, 30);
  padding: 0.5rem 1rem;
  color: #45d09a;
  font-weight: 700;
  border: 1px solid #45d09a !important;
}
.button {
  width: 50%;
  border: 0px;
  border-radius: 4px;
}
.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}
