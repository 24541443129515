.pair-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.pair-label {
  color: #ffffff;
  /* color: #45d09a; */
  margin: 0;
}

.expand-button {
  position: absolute;
  top: 0;
  left: 100px;
  padding: 4px;
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.extended-input {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 112px; /* Adjust for larger screens */
  background-color: #17171b;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: transform 0.2s ease;
}

.extended-input.show {
  transform: translateY(25px); /* Adjust for mobile */
}

.input-address {
  padding: 2px 5px;
  width: 160px;
  outline: none;
  border: 1px solid #45d09a;
  color: white;
  background: #45d09a33;
}

.submit-button {
  font-size: 10px;
  text-decoration: none;
  padding: 2px 4px;
  background-color: gray; /* Add your button background color */
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.pair-address {
  color: #ffffff;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .expand-button {
    left: 100px;
  }
  .extended-input {
    flex-direction: column; /* Stack elements on mobile */
    left: 0; /* Adjust positioning */
  }
}

@media (min-width: 1024px) {
  .expand-button {
    left: 100px;
  }
}
