.toggle-switch-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* flex-wrap: wrap; */
}
.switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2rem;
  margin: 0 0 0.5rem 0;
  flex-shrink: 0;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
  border-radius: 9999px;
  /* background-color: #353535; */
  background-color: #45d09a;
  transition: 0.4s;
}
.slider::before {
  position: absolute;
  content: "";
  left: 3px;
  bottom: 3px;
  width: 1.65rem;
  height: 1.65rem;
  background-color: #131313;
  border-radius: 9999px;
  transition: 0.4s;
}
input:checked + .slider::before {
  transform: translateX(1.9rem);
  /* background: linear-gradient(224deg, #3699b2 0%, #10cfa3 94.72%); */
  background: #45d09a;
}
input:checked + .slider {
  /* background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  ); */
  background: #45d09a33;
}

@media (max-width: 768px) {
  .switch {
    width: 3.5rem;
    height: 1.75rem;
  }

  .slider::before {
    width: 1.4rem;
    height: 1.4rem;
    left: 2.5px;
    bottom: 2.5px;
  }

  input:checked + .slider::before {
    transform: translateX(1.75rem);
  }
}

/* Css For Token Logo Toggle */
.slider-token-logo {
  background: linear-gradient(224deg, #3699b2 0%, #10cfa3 94.72%);
}

input:checked + .slider.slider-token-logo::before {
  transform: translateX(1.9rem);
  background: black;
}

input:checked + .slider.slider-token-logo {
  background: linear-gradient(224deg, #3699b2 0%, #10cfa3 94.72%);
}
