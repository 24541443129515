.dashboard-primary-bg {
  /* background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  ); */
}

.btn-bg-color {
  /* background: #17171b; */
  background: #45d09a;
}

.primary-text {
  color: #0ae7fe;
}

.gray-text {
  color: #9b9797;
}

/* Search input in sidebar */
.searchinput {
  border: 1px solid #f1f1f1;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 8px;
}

.searchinput::placeholder {
  color: #fff;
}

/* Media Query for Sidebar */
@media (max-width: 767px) {
  section {
    flex-direction: column;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 75%;
    max-width: 300px;
    height: 100%;
    background-color: #2c3e50;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    padding: 1rem;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  }

  .sidebar.open {
    transform: translateX(0);
  }
}
/* Media Query for DashBoard Container */
@media screen and (min-width: 320px) {
  .dashboardContainer {
    width: 100%;
  }
}

/* Media Query For Button Component */
@media screen and (min-width: 320px) {
  .dashboard-btn {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .dashboard-btn {
    width: 22rem;
  }
}
