.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal-container {
  background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  );
  color: #fff;
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  );
}

.custom-modal-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}
.splmodal-separator-line {
  width: 100%;
  height: 1px;
}

.custom-modal-body {
  padding: 16px;
  font-size: 1rem;
  background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  );
}

.spl-modal-description {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-align: center;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
  background: #333;
}

.custom-modal-button {
  padding: 8px 16px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #444;
  color: #fff;
}

.custom-modal-button:hover {
  background: #555;
}

.custom-modal-button.confirm {
  background: #0d6efd;
  color: #fff;
}

.custom-modal-button.confirm:hover {
  background: #0049b7;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
