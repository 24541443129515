/* White Color */
.white-color {
  color: #f1f1f1;
}

.modal-header {
  color: #f1f1f1;
  /* margin: 0px 0px 10px 0px; */
  font-weight: 700;
}
.separator-line {
  width: 100%;
  height: 1px;
  background-color: #333;
  margin-bottom: 1rem;
}
.para {
  color: #f1f1f1;
  font-weight: 700;
}
.modal-description {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2886) 0%,
    rgba(255, 255, 255, 0.1961) 100%
  );
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-align: center;
}
.wallet-address {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  cursor: pointer;
}
.payment-btn {
  /* background-color: #17171b; */
  background: #45d09ae5;
  color: #ffffff;
  border: 0px;
  border-radius: 4px;
  width: 100%;
  padding: 0.5rem 1rem;
  display: block;
  margin: auto;
}
/* Outer Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.outer-modal {
  /* background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  ); */
  background: linear-gradient(
    180deg,
    rgba(69, 208, 154, 0.5032) 0%,
    rgba(92, 255, 192, 0.2584) 100%
  );
  backdrop-filter: blur(8px);
  padding: 1.25rem;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 700px;
  text-align: left;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* Inner Modal Styles */
.inner-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.inner-modal {
  /* background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  ); */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2886) 0%,
    rgba(255, 255, 255, 0.1961) 100%
  );

  backdrop-filter: blur(20px);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 700px;
  text-align: left;
}
.deploy-modal-header {
  color: #ffffff;
}
.deploy-description {
  color: rgba(255, 255, 255, 1);
}
.yes-btn {
  background: rgba(69, 208, 154, 0.9);
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-weight: 600;
}
.no-btn {
  background: rgba(30, 30, 30);
  padding: 0.5rem 1rem;
  color: #45d09a;
  font-weight: 600;
  border: 1px solid #45d09a !important;
}
.button {
  width: 100%;
  border: 0px;
  border-radius: 4px;
}
.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* CSS For Seed Increment and Decrement  */
.seed-container {
  background: rgba(255, 255, 255, 0.1);
  width: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}
.seed-container button {
  width: 3rem;
  padding: 0.8rem;
  background: linear-gradient(226deg, #074959 -39.46%, #0a0a0d 94.74%);
  border: none;
  border-radius: 0.3rem;
}

/* Removes the outline when input is in focus state */
.no-outline:focus {
  outline: none;
  box-shadow: none;
}

/* Download and Deploy Button */
.download-btn,
.deploy-btn {
  background: #45d09ae5;
  color: #ffffff;
  border: 0px;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}
.download-btn,
.deploy-btn {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .download-btn,
  .deploy-btn {
    width: 100%;
  }
}
/* CSS For Toast Notification */
.toast-container {
  /* background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.8) 0%,
    rgba(16, 207, 163, 0.8) 94.72%
  ); */
  background: #45d09ae5;
  color: #ffffff;
  font-weight: 700;
}

.toast-progress-bar {
  background: #b4ffe2e5;
}
.teal-toast-progress-bar {
  background: #b4ffe2e5;
}
