/* ImageInput.css */
.custom-file-input {
  background-color: #17171b;
  color: white;
  border: 1.3px solid gray;
  padding: 0.6rem 0.7rem;
  outline: none;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
}

.custom-file-input::-webkit-file-upload-button {
  color: white;
  background-color: #17171b;
  border: none;
}

.custom-file-input-url {
  cursor: auto;
}

/* Image Preview CSS */
.image-input-container {
  position: relative;
  width: 100%;
}

.image-preview-container {
  position: relative;
  display: inline-block;
  margin-top: 0.5rem;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.delete-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.445);
  border: none;
  border-radius: 2px;

  cursor: pointer;
  padding: 0;
}
.delete-btn:hover {
  color: red;
}
