@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

body {
  font-family: "DM Sans", sans-serif;
  /* background-color: radial-gradient(
    50% 50% at 50% 50%,
    #0d0d0e 0%,
    #0c0c0f 100%
  ); */
  background: black;
}

:root {
  /* --primary-color: linear-gradient(rgb(19 107 127), rgb(6 141 114)); */
  --primary-color: #45d09a;
  --secondary-solid-color: rgb(6 141 114);
}

/* CSS Summary Table */
.table-heading_color {
  color: #0ae7fe;
}
.table-text-color {
  color: #45d09a;
}

/* CSS For PlaceHolder */
.placeholderColor::placeholder {
  /* color: #545454; */
  color: #45d09a;
}

.loader {
  width: 150px;
  height: 150px;
  border: 10px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Pending Deploy  */
.pendingDeploy-Bg {
  /* background-color: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.25) 0%,
    rgba(16, 207, 163, 0.25) 94.72%
  ); */

  background-color: #17171b;
}

/* Setting MArgin to P tag */
/* .zeroMargin {
  margin: 0;
} */

.bg-btn-color {
  background: var(--primary-color);
}
.save-next-btn {
  background: var(--primary-color);
  border-radius: 4px;
  padding: 6px 12px;
  border: none;
  outline: none;
  color: white;
}
.border-color {
  background: transparent;
  color: #fff;
  border: 1px solid #45d09a;
  border-radius: 4px;
  padding: 0.3rem 1rem;
}
p {
  margin: 0;
}

/* Text Color */
.text-white {
  color: #fff;
}
.text-gray {
  color: #d7d7d7;
}

/* General Tab */
/* CSS For Decimal Increment and Decrement  */
.decimal-container {
  /* background: #131313; */
  background: #45d09a33;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.5rem;
  border-radius: 8px;
}
.decimal-container button {
  width: 2.4rem;
  padding: 0.24rem;
  /* background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  ); */
  background: #45d09a;
  border: none;
  border-radius: 0.3rem;
}
.custom-container {
  flex: 5;
  background: #232323;
  border: 0.5px solid rgba(114, 138, 150, 0.24);
  box-shadow: 0px 2px 16px rgba(114, 138, 150, 0.08);
  border-radius: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  width: 95%;
  /* min-height: 30rem; Default for smaller screens */
  background-image: url("./Images//BgPattern/pattern-stack.png"); /* Replace with the correct path to your background image */
}

/* Media query for screens with a width of 720px or more */
/* @media (min-width: 720px) {
  .custom-container {
    height: 70rem;
  }
} */

/* Media query for screens with a width of 1024px or more */
/* @media (min-width: 1024px) {
  .custom-container {
    height: 45rem;
  }
} */

/* Media query for screens with a width of 1440px or more */
/* @media (min-width: 1440px) {
  .custom-container {
    height: 70rem;
  }
} */
