.checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
input[type="checkbox"] {
  position: relative;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 3px;
  appearance: none;
  cursor: pointer;
  border: 1px solid white;
}
input[type="checkbox"]:checked {
  /* background: linear-gradient(
    224deg,
    rgba(54, 153, 178, 0.5) 0%,
    rgba(16, 207, 163, 0.5) 94.72%
  ); */
  background: #45d09a;
  border: none;
}
input[type="checkbox"]::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 13px;
  top: 5px;
  left: 9px;
  border: 2px solid transparent;
  border-left: none;
  border-top: none;
  transform: rotate(45deg) scale(1);
}
input[type="checkbox"]:checked:before {
  border-color: #fff;
  animation: checkanim 0.2s ease;
}
@keyframes checkanim {
  from {
    transform: rotate(45deg) scale(0);
  }
}
